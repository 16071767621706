import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { DatePicker, Empty, Row, Select, Space, Spin, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../../../components/layout/page-title/PageTitle";
import WheelService from "../../../services/api/WheelService";

const { Option } = Select;
const { RangePicker } = DatePicker;

// Constants
const CHART_COLOR = "#a700ba";

// Helper Functions
const formatDate = (date) => moment(date).format("YYYY/MM/DD");

const WheelChartPage = () => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [dropdown, setDropdown] = useState("year");

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await WheelService.getAllWheels(dateRange[0], dateRange[1]);
        setData(res.data.data.wheels);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (dateRange.length) {
      getData();
    }
  }, [dateRange]);

  useEffect(() => {
    const to = moment().toISOString();

    const dateRanges = {
      month: moment().subtract(1, "month").toISOString(),
      "3month": moment().subtract(3, "months").toISOString(),
      "6month": moment().subtract(6, "months").toISOString(),
      year: moment().subtract(1, "year").toISOString(),
      "5year": moment().subtract(5, "years").toISOString(),
    };

    setDateRange([dateRanges[dropdown] || dateRanges.month, to]);
  }, [dropdown]);

  const chartNames = useMemo(
    () => [
      "pointMind",
      "pointBody",
      "pointSoul",
      "pointSocial_love",
      "pointSocial_sexuality",
      "pointSocial_money",
      "pointSocial_success",
      "pointSocial_environment",
      "pointSocial_conflict",
      "pointSocial_education",
    ],
    []
  );

  const chartNamesMap = useMemo(
    () => ({
      pointBody: "Body HPS",
      pointMind: "Mind HPS",
      pointSoul: "Soul HPS",
      pointSocial_love: "Social Aspects - Relationship/Love HPS",
      pointSocial_sexuality: "Social Aspects - Sexuality HPS",
      pointSocial_money: "Social Aspects - Money/Income HPS",
      pointSocial_success: "Social Aspects - Career/Occupation HPS",
      pointSocial_environment: "Social Aspects - Environment HPS",
      pointSocial_conflict: "Social Aspects - Peace/Conflict HPS",
      pointSocial_education: "Social Aspects - Education HPS",
    }),
    []
  );

  const chartData = useMemo(() => {
    if (!data) return undefined;

    const output = {};

    data.forEach((wheel) => {
      for (const property in wheel) {
        if (chartNames.includes(property)) {
          const newVal = {
            value: wheel[property],
            date: wheel["createdAt"],
          };

          if (output[property]) {
            output[property] = [...output[property], newVal];
          } else {
            output[property] = [newVal];
          }
        }
      }
    });

    return output;
  }, [data, chartNames]);

  const totalChartData = useMemo(() => {
    if (!data) return [];

    return data.map((rest) => {
      const data = Object.keys(rest).filter((key) => key.startsWith("point"));
      const values = data.map((key) => rest[key]);
      const sum = values.reduce((a, b) => a + b, 0);
      const notZeros = values.filter((val) => val);
      const result = `${sum}/${notZeros.length * 10}`;

      return {
        value: sum,
        label: result,
        date: rest["createdAt"],
      };
    });
  }, [data]);

  const renderChart = (data, title, color) => (
    <div key={title} style={{ marginBottom: "50px" }}>
      <Typography.Title level={3}>{title}</Typography.Title>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={formatDate}
            tick={{ fontSize: 12 }}
          />
          <YAxis domain={[0, 100]} tick={{ fontSize: 12 }} />
          <Tooltip
            content={({ payload }) => {
              if (payload && payload.length > 0) {
                return (
                  <div
                    style={{
                      backgroundColor: "#fff",
                      padding: "5px 10px",
                      border: "1px solid #ddd",
                    }}
                  >
                    value: {payload[0].payload.value.toFixed(1)}

                    <div>
                      date: {formatDate(payload[0].payload.date)}
                    </div>
                  </div>
                );
              }
              return null;
            }}
          />
          <Line
            type="line"
            dataKey="value"
            stroke={color}
            strokeWidth={2}
            // dot={{ r: 4, fill: color, stroke: "#fff", strokeWidth: 2 }}
            dot={{
              r: 4,
              fill: "white",
              stroke: CHART_COLOR,
              strokeWidth: 2,
            }}
            activeDot={{
              r: 6,
              fill: CHART_COLOR,
              stroke: "white",
              strokeWidth: 2,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );

  const charts = useMemo(() => {
    if (!chartData) return null;

    return Object.keys(chartData).map((key) => {
      const data = chartData[key].sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
      const title = chartNamesMap[key] || key;
      return renderChart(data, title, CHART_COLOR);
    });
  }, [chartData, chartNamesMap]);

  const onDateRangePicker = (momentRange) => {
    setDateRange(momentRange.map((m) => m.toISOString()));
  };

  const displayCharts = charts?.length ? <>{charts}</> : <Empty />;
  const displayTotalChart = totalChartData?.length
    ? renderChart(totalChartData, "Total HPS", CHART_COLOR)
    : null;

  const spinner = (
    <Row justify="center">
      <Spin size="large" />
    </Row>
  );

  return (
    <>
      <PageTitle title="Dashboard" />
      <div style={{ marginBottom: "50px" }}>
        <Space>
          <Select defaultValue="year" value={dropdown} onChange={setDropdown}>
            <Option value="month">1 Month</Option>
            <Option value="3month">3 Months</Option>
            <Option value="6month">6 Months</Option>
            <Option value="year">Year</Option>
            <Option value="5year">5 Years</Option>
            <Option value="custom">Custom</Option>
          </Select>

          {dropdown === "custom" ? (
            <RangePicker onChange={onDateRangePicker} />
          ) : null}
        </Space>
      </div>
      {loading ? spinner : [displayTotalChart, displayCharts]}
    </>
  );
};

export default WheelChartPage;
