import React from "react";
import { Card, Row, Col, Typography } from "antd";

import {
  AppstoreOutlined
} from "@ant-design/icons";
import PageTitle from "../../components/layout/page-title/PageTitle";
import "./DocumentsPage.css"; // Import custom CSS for hover animation

const { Title } = Typography;

const DocumentsPage = () => {


  const mealPlans = [
    {
        title: '900 omini',
        link: 'https://heatwellbeing.com/dl/meal-plans/900-omni.pdf',
        icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
    },
    {
        title: '900 veg',
        link: 'https://heatwellbeing.com/dl/meal-plans/900-veg.pdf',
        icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
    },
    {
        title: '1200 omi',
        link: 'https://heatwellbeing.com/dl/meal-plans/1200-omni.pdf',
        icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
    },
    {
        title: '1200 veg',
        link: 'https://heatwellbeing.com/dl/meal-plans/1200-veg.pdf',
        icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
    },
    {
        title: '1500 omni',
        link: 'https://heatwellbeing.com/dl/meal-plans/1500-omni.pdf',
        icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
    },
    {
        title: '1500 veg',
        link: 'https://heatwellbeing.com/dl/meal-plans/1500-veg.pdf',
        icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
    },
    {
        title: '1800 omni',
        link: 'https://heatwellbeing.com/dl/meal-plans/1800-omni.pdf',
        icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
    },
    {
        title: '1800 veg',
        link: 'https://heatwellbeing.com/dl/meal-plans/1800-veg.pdf',
        icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
    },
    {
        title: '2100 omni',
        link: 'https://heatwellbeing.com/dl/meal-plans/2100-omni.pdf',
        icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
    },
    {
      title: '2100 veg',
      link: 'https://heatwellbeing.com/dl/meal-plans/2100-veg.pdf',
      icon: <AppstoreOutlined style={{ fontSize: '32px' }} />,
  },
];

  return (
    <>
      <PageTitle title="Meal Plans" />
      <div style={{ marginBottom: "50px", padding: "24px" }}>
        <Title level={3}>Explore Meal Plans</Title>
        <Row gutter={[16, 16]}>
          {mealPlans.map((document, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <Card
                hoverable
                onClick={() => window.open(document.link, '_blank')} // Open link in new tab
                className="document-card" // Add a custom class for hover animation
                style={{
                  height: "180px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease", // Smooth transition
                }}
              >
                <div style={{ marginBottom: "16px" }}>{document.icon}</div>
                <Title
                  level={4}
                  style={{ color: "inherit", margin: 0, fontSize: "16px" }}
                >
                  {document.title}
                </Title>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default DocumentsPage;
