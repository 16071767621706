import React from "react";
import { Card, Row, Col, Typography } from "antd";
import { useHistory } from 'react-router-dom';

import {
  FileImageOutlined
} from "@ant-design/icons";
import PageTitle from "../../components/layout/page-title/PageTitle";
import "./DocumentsPage.css"; // Import custom CSS for hover animation

const { Title } = Typography;

const DocumentsPage = () => {
  const history = useHistory();

  // Define the mealPlans with their titles, download links, and icons
  const mealPlans = [
    {
      title: "Defense Mechanisms of the Ego",
      downloadLink: "/resources/Defense Mechanisms of the Ego.png",
      icon: <FileImageOutlined style={{ fontSize: "32px" }} />,
    },
    {
      title: "TFA Triangle",
      downloadLink: "/resources/TFA Triangle.png",
      icon: <FileImageOutlined style={{ fontSize: "32px" }} />,
    },
    {
      title: "TFA Reactions",
      downloadLink: "/resources/TFA Reactions.png",
      icon: <FileImageOutlined style={{ fontSize: "32px" }} />,
    },
  ];


 

  return (
    <>
      <PageTitle title="Other Documents" />
      <div style={{ marginBottom: "50px", padding: "24px" }}>
        <Title level={3}>Explore Other Documents</Title>
        <Row gutter={[16, 16]}>
          {mealPlans.map((document, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <Card
                hoverable
                onClick={() => history.push(document.link)} // Open download link in new tab
                className="document-card" // Add a custom class for hover animation
                style={{
                  height: "180px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease", // Smooth transition
                }}
              >
                <div style={{ marginBottom: "16px" }}>{document.icon}</div>
                <Title
                  level={4}
                  style={{ color: "inherit", margin: 0, fontSize: "16px" }}
                >
                  {document.title}
                </Title>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default DocumentsPage;
