import { Line } from "@ant-design/charts";
import { DatePicker, Empty, Row, Select, Space, Spin, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../../../components/layout/page-title/PageTitle";
import WheelService from "../../../services/api/WheelService";

const { Option } = Select;
const { RangePicker } = DatePicker;

const WheelChartPage = () => {
  const CHART_COLOR = "#a700ba";

  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [dropdown, setDropdown] = useState("year");

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await WheelService.getAllWheels(dateRange[0], dateRange[1]);
        setData(res.data.data.wheels);
        setLoading(false);
      } catch (error) {}
    };

    if (dateRange.length) {
      getData();
    }
  }, [dateRange]);

  useEffect(() => {
    const to = moment().toISOString();

    switch (dropdown) {
      case "month":
        setDateRange([moment().subtract(1, "month").toISOString(), to]);
        break;

      case "3month":
        setDateRange([moment().subtract(3, "months").toISOString(), to]);
        break;

      case "6month":
        setDateRange([moment().subtract(6, "months").toISOString(), to]);
        break;

      case "year":
        setDateRange([moment().subtract(1, "year").toISOString(), to]);
        break;

      case "5year":
        setDateRange([moment().subtract(5, "years").toISOString(), to]);
        break;

      default:
        setDateRange([moment().subtract(1, "month").toISOString(), to]);
    }
  }, [dropdown]);

  const chartNames = useMemo(
    () => [
      "pointMind",
      "pointBody",
      "pointSoul",
      "pointSocial_love",
      "pointSocial_sexuality",
      "pointSocial_money",
      "pointSocial_success",
      "pointSocial_environment",
      "pointSocial_conflict",
      "pointSocial_education",
    ],
    []
  );

  const chartNamesMap = useMemo(
    () => ({
      pointBody: "Body HPS",
      pointMind: "Mind HPS",
      pointSoul: "Soul HPS",
      pointSocial_love: "Social Aspects - Relationship/Love HPS",
      pointSocial_sexuality: "Social Aspects - Sexuality HPS",
      pointSocial_money: "Social Aspects - Money/Income HPS",
      pointSocial_success: "Social Aspects - Career/Occupation HPS",
      pointSocial_environment: "Social Aspects - Environment HPS",
      pointSocial_conflict: "Social Aspects - Peace/Conflict HPS",
      pointSocial_education: "Social Aspects - Education HPS",
    }),
    []
  );

  const chartData = useMemo(() => {
    if (!data) return undefined;

    const output = {};

    data.forEach((wheel) => {
      for (const property in wheel) {
        if (chartNames.includes(property)) {
          const newVal = {
            value: wheel[property],
            date: wheel["createdAt"],
          };

          if (output[property]) {
            output[property] = [...output[property], newVal];
          } else {
            output[property] = [newVal];
          }
        }
      }
    });

    return output;
  }, [data, chartNames]);

  const safeData = data || [];
  const totalChartData = safeData.map((rest) => {
    const data = Object.keys(rest).filter((key) => key.startsWith("point"));
    const values = data.map((key) => rest[key]);
    const sum = values.reduce((a, b) => a + b, 0);
    const notZeros = values.filter((val) => val);
    const result = `${sum}/${notZeros.length * 10}`;
    const height = 100;

    return {
      // value: sum / (notZeros.length * 10),
      value: sum,
      label: result,
      date: rest["createdAt"],
      height: height,
    };
  });

  const totalChartConfig = {
    data: totalChartData,

    xField: "date",
    yField: "value",
    axis: {
      x: {
        labelFormatter: (v) => moment(v).format("MMM Do YY"),
        labelFontSize: 15,
      },
      y: {
        min: 0,
        max: 100,
      },
    },
    legend: {
      size: {},
    },
    // label: {
    //   formatter: (label) => {
    //     return label;
    //   },
    //   fontSize: 10,
    // },
    point: {
      shape: "diamond",
    },
    tooltip: {
      title: (d) => moment(d.date).format("MMM Do YY"),
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
    color: CHART_COLOR,
  };

  // console.log(totalChartConfig);

  const totalChart = (
    <div key="total-chart" style={{ marginBottom: "50px" }}>
      <Typography.Title level={3}>Total HPS</Typography.Title>
      <Line la {...totalChartConfig} />
    </div>
  );

  const charts = chartData
    ? Object.keys(chartData).map((key) => {
        const data = chartData[key];

        var config = {
          data: data,
          xField: "date",
          yField: "value",
          axis: {
            x: {
              labelFormatter: (v) => moment(v).format("MMM Do YY"),
            },
          },
          point: {
            sizeField: 5,
            size: 20,
            shape: "square",
            style: {
              fill: "white",
              stroke: CHART_COLOR,
              lineWidth: 2,
            },
          },
          tooltip: {
            // channel: 'y', valueFormatter: (v) => moment(v).format('MMM Do YY')
          },
          state: {
            active: {
              style: {
                shadowBlur: 4,
                stroke: "#000",
                fill: "red",
              },
            },
          },
          interactions: [
            {
              type: "marker-active",
            },
          ],
          color: CHART_COLOR,
        };

        const title = chartNamesMap[key] || key;

        return (
          <div key={title} style={{ marginBottom: "50px" }}>
            <Typography.Title level={3}>{title}</Typography.Title>
            <Line {...config} />
          </div>
        );
      })
    : null;

  const onDateRangePicker = (momentRange) => {
    setDateRange(momentRange.map((m) => m.toISOString()));
  };

  const displayCharts = charts?.length ? <>{charts}</> : <Empty />;
  const displayTotalChart = totalChartData?.length ? <>{totalChart}</> : null;

  const spinner = (
    <Row justify="center">
      <Spin size="large" />
    </Row>
  );

  return (
    <>
      <PageTitle title="Dashboard" />
      <div style={{ marginBottom: "50px" }}>
        <Space>
          <Select defaultValue="year" value={dropdown} onChange={setDropdown}>
            <Option value="month">1 Month</Option>
            <Option value="3month">3 Months</Option>
            <Option value="6month">6 Months</Option>
            <Option value="year">Year</Option>
            <Option value="5year">5 Years</Option>
            <Option value="custom">Custom</Option>
          </Select>

          {dropdown === "custom" ? (
            <RangePicker onChange={onDateRangePicker} />
          ) : null}
        </Space>
      </div>
      {loading ? spinner : [displayTotalChart, displayCharts]}
    </>
  );
};

export default WheelChartPage;
