import { Select } from 'antd';
import { useEffect, useState } from 'react';
import convert from 'convert-units';

const { Option } = Select;

export const measures = {
  mass: ['lb', 'kg'],
  length: ['ft', 'cm'],
};

const toSaveUnits = {
  mass: 'kg',
  length: 'cm',
};

const toShowUnits = {
  mass: 'lb',
  length: 'cm',
};

export const convertToShow = (value, measure) => {
  return convert(value).from(toSaveUnits[measure]).to(toShowUnits[measure]);
};

const ConverUnit = ({ measure, value = 0, onChange = () => {} }) => {
  const defaultValue =
    measure && Array.isArray(measures[measure]) && measures[measure].length
      ? measures[measure][0]
      : '';

  const [selectedUnit, setSelectedUnit] = useState();

  useEffect(() => {
    if (!value) {
      return onChange(undefined);
    }

    const newValue = convert(value).from(selectedUnit).to(toSaveUnits[measure]);
    onChange(newValue);
  }, [selectedUnit, value, measure, onChange]);

  useEffect(() => {
    setSelectedUnit(defaultValue)
  }, [measure])
  

  if (!measure) {
    return null;
  }

  const radioButtons = measures[measure].map((unit) => (
    <Option key={`radio-${unit}`} value={unit}>
      {unit}
    </Option>
  ));

  return (
    <div>
      <Select
        value={selectedUnit}
        onChange={(e) => setSelectedUnit(e)}
        style={{ width: '100%' }}
      >
        {radioButtons}
      </Select>
    </div>
  );
};

export default ConverUnit;
