/* eslint-disable no-unused-vars */
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Space, Table, Typography } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { convertToShow } from '../../../components/inputs/ConverUnit';
import useQuery from '../../../hooks/useQuery';
import AnswerService from '../../../services/api/AnswerService';
import { toFixedIfNecessary } from '../../../utils/number';
import { limmiter } from '../../../utils/string';
import { toShowUnits } from '../../../utils/units';
import AnswersChart from './components/AnswersChart';
import EditAnswerModal from './modals/EditAnswerModal';

const AnswersPage = ({
  questionGroupId,
  selectedQuestionItem,
  setSelectedQuestionItem,
  getAnswers,
  pleaseUpdate
}) => {
  const questionId = selectedQuestionItem?.key;

  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [currentQA, setCurrentQA] = useState({});


  const [editModalData, setEditModalData] = useState(undefined);

  const convertData = () => {
    
    setLoading(true);
    setAnswers(
      getAnswers.map((QA) => {
        const { question } = QA;
        let answer;
        if (question.measure) {
          const value = JSON.parse(QA.answer).value;
          const newValue = convertToShow(value, question.measure);

          answer = JSON.stringify({ value: newValue });
        } else {
          answer = QA.answer;
        }

        return { ...QA, answer, key: QA.id };
      }),
    );
    setLoading(false);
  };

  useEffect(() => {
    convertData();
  }, [questionId, questionGroupId,pleaseUpdate]);

  const deleteAnswer = useCallback(async (id) => {
    try {
      await AnswerService.deleteAnswer(id);
      message.success('Deleted successfully.');
      // await getAnswers();
    } catch (error) {
      message.error(error.message);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'Metric',
        dataIndex: 'question',
        render: (question) => {
          return (
            <Typography.Link
              onClick={() => {
                const data = {
                  key: question.id,
                  label: question.title,
                  value: question.id,
                  type:question.type
                };

                setSelectedQuestionItem(data);
              }}
            >
              {question.title}
            </Typography.Link>
          );
        },
      },
      // {
      //   title: 'Answer',
      //   dataIndex: 'answer',
      //   width: '50%',
      //   render: (answer, { question }) => {
      //     const options = JSON.parse(question.options);
      //     let { value } = JSON.parse(answer);

      //     if (question.type === 'radio') {
      //       value = [value];
      //     }

      //     if (question.type === 'number') {
      //       value = toFixedIfNecessary(value, 2);
      //     }

      //     if (Array.isArray(value)) {
      //       value = value.map((v) => options.find((o) => o.value === v).title);
      //     }

      //     const questionMeasure = question.measure
      //       ? toShowUnits[question.measure]
      //       : null;

      //     return limmiter(`${value.toString()} ${questionMeasure== null ? "" :questionMeasure}`, 80);
      //   },
      // },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (time) => {
          return moment(time).format('YYYY-MM-DD hh:mm:ss');
        },
      },
      {
        title: 'Actions',
        dataIndex: 'operation',
        width: '10%',
        render: (_, record) =>
          answers.length >= 1 ? (
            <Space direction="horizontal" align="end" wrap>
              <Button
                type="primary"
                icon={<EditOutlined />}
                size="small"
                onClick={() => {
                  setEditModalData(record);
                }}
              />

              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => deleteAnswer(record.id)}
              >
                <Button icon={<DeleteOutlined />} size="small" danger />
              </Popconfirm>
            </Space>
          ) : null,
      },
    ],
    [answers, deleteAnswer, setSelectedQuestionItem],
  );

  return (
    <>

      <Table dataSource={answers} columns={columns} loading={loading} />

      <EditAnswerModal
        // key={}
        visible={Boolean(editModalData)}
        dismiss={() => setEditModalData(undefined)}
        answer={editModalData}
        getAnswers={getAnswers}
        pleaseUpdate={pleaseUpdate}
      />
    </>
  );
};

export default AnswersPage;
