import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  message,
  Row,
  Spin,
  DatePicker,
  Tag,
  Radio,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

import ConverUnit, { measures } from '../../../components/inputs/ConverUnit';
import Question from '../../../components/inputs/Question';
import PageTitle from '../../../components/layout/page-title/PageTitle';
import { DebounceSelect } from '../../../components/wrappers/inputs';
import AnswerService from '../../../services/api/AnswerService';
import QuestionService from '../../../services/api/QuestionService';
import AnswersPage from '../../tables/answers/AnswersPage';
import AnswersChart from '../../../pages/tables/answers/components/AnswersChart';
import _ from "lodash";


import './styles.less';
import moment from 'moment';
import convert from 'convert-units';
import { toSaveUnits } from '../../../utils/units';

const { Panel } = Collapse;

const MyHealthWellnessData = () => {
  const [createLoading, setCreateLoading] = useState(false);
  const [convertedValue, setConvertedValue] = useState(undefined);
  const [isModifying, setModifying] = useState(false);
  const [currentEditingData, setCurrentEditingData] = useState({});
  const [pleaseUpdate, setPleaseUpdate] = useState({});
  const [chartUnit, setChartUnit] = useState("");

  const [QAList, setQAList] = useState(undefined);
  const [date, setDate] = useState(moment());
  const [selectedQuestionGroupId, setSelectedQuestionGroupId] = useState(undefined);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestionItem, setSelectedQuestionItem] = useState(undefined);
  const [answer, setAnswer] = useState(undefined);
  const [selectedTags, setSelectedTags] = useState([]); // State for selected tags
  const [form] = Form.useForm();


  useEffect(() => {
    const localStorageTagsList = JSON.parse(localStorage.getItem("tagsList"))
    if (localStorageTagsList?.length) { 
      setSelectedTags(localStorageTagsList)
    }
  }, [])

  useEffect(() => {
    setConvertedValue(undefined);
    loadQA();
    getQuestions();
  }, [pleaseUpdate]);


  useEffect(() => {
    if (selectedTags.length) {
      localStorage.setItem("tagsList", JSON.stringify(selectedTags))
    }
  }, [selectedTags])
  

  const loadQA = async () => {
    const res = await AnswerService.getAllAnswersById();
    let dataSort = res.data.data.answers.sort(
      (a, b) => a.createdAt - b.createdAt
    );
    setQAList(dataSort);
  };

  const onFinish = async (values) => {
    if (convertedValue) {
      values = { [Object.keys(values)[0]]: convertedValue };
    }

    setCreateLoading(true);
    if (isModifying) {
      try {
        await AnswerService.editAnswer(currentEditingData.id, values);
        message.success("Your answer has been saved.");
        resetQuestionAndAnswer();
        setPleaseUpdate();
        window.location.reload();
      } catch (error) {
        message.error(error.message);
      }
    } else {
      try {
        const withKeyData = { ...values, createdAt: date };
        await AnswerService.createNewAnswer(withKeyData);
        message.success("Your answer has been saved.");
        resetQuestionAndAnswer();
        setPleaseUpdate();
        window.location.reload();
      } catch (error) {
        message.error(error.message);
      }
    }
    setCreateLoading(false);
  };

  const setFormData = (data) => {
    form.setFieldsValue({
      [data.question.id]: JSON.parse(data.answer).value,
    });
  };

  const getQuestions = useCallback(
    async (searchTerm = "") => {
      setCreateLoading(true)
      try {
        const res = await QuestionService.getAllQuestions(
          searchTerm,
          selectedQuestionGroupId
        );
        const mapped = res.data.data.questions.map((item) => ({
          label: item.title,
          value: item.id,
          groupName: item.groupName,
        }));
        setQuestions(res.data.data.questions);
        return mapped;
        
      } catch (error) {
        console.log(error);
      } finally {
        setCreateLoading(false) 
      }
   
    },
    [selectedQuestionGroupId]
  );

  const selectedQuestion = useMemo(() => {
    const question = questions.find((q) => q.id === selectedQuestionItem?.key);
    if (!question) return undefined;
    let output;
    const { options } = question;
    if (options) {
      output = { ...question, options: JSON.parse(question.options) };
    } else {
      output = { ...question };
    }
    return output;
  }, [questions, selectedQuestionItem]);

  // Handle selection of an item from the dropdown
  const handleSelectItem = (value) => {
    setSelectedQuestionItem(value);

    // Add the selected item to the tags list if it doesn't already exist
    if (!selectedTags.some((tag) => tag.key === value.key)) {
      setSelectedTags([...selectedTags, value]);
    }
  };

  // Handle removal of a tag
  const handleRemoveTag = (removedTag) => {
    const updatedTags = selectedTags.filter(
      (tag) => tag.key !== removedTag.key
    );
    setSelectedTags(updatedTags);

    // If the removed tag is the currently selected item, reset the selected item
    if (selectedQuestionItem?.key === removedTag.key) {
      setSelectedQuestionItem(undefined);
    }
  };

  // Handle click on a tag
  const handleTagClick = (tag) => {
    setSelectedQuestionItem(tag);
    setPleaseUpdate({})
  };

  const questionSelect = (
    <DebounceSelect
      className="wellnesssearch"
      size="large"
      placeholder="Data Entry"
      fetchOptions={getQuestions}
      onChange={handleSelectItem} // Use the new handler
      value={selectedQuestionItem}
      style={{ width: "100%", paddingBottom: "10px" }}
      debounceTimeout={2000}
      showSearch
      withGroup
    />
  );

  const resetQuestionAndAnswer = () => {
    form.resetFields();
    setDate(moment());
    setModifying(false);
    setCurrentEditingData({});
    setSelectedQuestionGroupId(undefined);
    setSelectedQuestionItem(undefined);
    setSelectedTags([]); // Clear tags on reset
  };

  const dateIsUpdated = useEffect(() => {
    let datefound = false;
    let dataPerDay = [];
    QAList?.forEach((fdata) => {
      if (
        moment(date)
          .startOf("day")
          .isSame(moment(fdata.createdAt).startOf("day")) &&
        fdata.question.id == selectedQuestionItem?.key
      ) {
        dataPerDay.push(fdata);
        setFormData(fdata);
        datefound = true;
        setModifying(true);
        setCurrentEditingData(fdata);
      } else {
        form.resetFields();
        setModifying(false);
        setCurrentEditingData({});
      }
    });
  }, [date, selectedQuestion, pleaseUpdate]);

  let questionComponent = null;
  if (selectedQuestion) {
    questionComponent = (
      <Question
        {...selectedQuestion}
        onChange={(e) => setAnswer(e)}
        value={answer}
      />
    );
  }

  const hasChart = useMemo(() => {
    if (selectedQuestionItem) {
      if (!QAList?.length) return false;
      let AnswersOnly = [];
      QAList.forEach((element) => {
        if (element.answer && element.question.type === "number") {
          AnswersOnly.push(JSON.parse(element.answer)?.value);
        }
      });
      return AnswersOnly;
    }
  }, [selectedQuestionItem]);

  const convertToShowOnChart = (value, measure, convertTo) => {
    return Math.round(convert(value).from(toSaveUnits[measure]).to(convertTo) * 100) / 100;
  };

  useEffect(() => {
    if (selectedQuestion && selectedQuestion.measure) {
      setChartUnit(measures[selectedQuestion.measure][0]);
    }
  }, [selectedQuestion]);

  

  const chart =
    hasChart && QAList && selectedQuestion != undefined ? (
      <div style={{ margin: "20px 0" }}>
        {selectedQuestion.measure ? (
          <Radio.Group
            onChange={(e) => setChartUnit(e.target.value)}
            value={chartUnit}
            style={{ marginBottom: 8 }}
          >
            {measures[selectedQuestion.measure].map((measure) => (
              <Radio.Button value={measure}>{measure}</Radio.Button>
            ))}
          </Radio.Group>
        ) : (
          <></>
        )}

        <AnswersChart
          data={QAList.map((item) => {
            let dataForChart = {};
            if (selectedQuestionItem?.key === item.question.id) {
              let d;
              d = JSON.parse(item.answer)?.value;

              
              if (item.question?.measure) {
                const isChartUnitCompatible = chartUnit && measures[item.question.measure].includes(chartUnit)
                if (isChartUnitCompatible) {              
                  d = JSON.parse(item.answer)?.value;
                  d = convertToShowOnChart(d, selectedQuestion.measure, chartUnit);
                }
              } else {
                d = JSON.parse(item.answer)?.value;
              }
              dataForChart = {
                date: moment(item.createdAt).format("YYYY/MM/DD"),
                value: d,
              };
            }
            return dataForChart;
          })
            .filter((item) => item.value)
            .reverse()}
        />
      </div>
    ) : null;

  const hasMeasure =
    selectedQuestion &&
    selectedQuestion.type === "number" &&
    selectedQuestion.measure;

  const formComponent = selectedQuestion ? (
    <Spin spinning={createLoading}>
      <Divider />
      <div style={{ marginTop: "20px" }} />
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[2]}>
          <Col span={hasMeasure ? 3 : 5}>
            <Form.Item
              label={selectedQuestion.title}
              name={selectedQuestionItem.key}
              rules={[
                { required: true, message: "Please answer this question!" },
              ]}
            >
              {questionComponent}
            </Form.Item>
          </Col>
          {hasMeasure ? (
            <Col span={2}>
              <Form.Item label=" ">
                <ConverUnit
                  measure={selectedQuestion?.measure}
                  value={form.getFieldValue(selectedQuestionItem.key)}
                  onChange={setConvertedValue}
                />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <div style={{ marginTop: "20px" }} />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  ) : null;

  return (
    <div className="question-section">
      <PageTitle title="My Health & Wellness Data" />
      <Row gutter={[5]}>
      {selectedQuestion ? (
          <DatePicker
            size="large"
            value={date}
            style={{ marginBottom: "3%", borderRadius: "30px" }}
            dateRender={(current) => {
              const style = {};
              return (
                <div className="ant-calendar-date" style={style}>
                  {current.date()}
                </div>
              );
            }}
            defaultValue={date}
            onChange={(date) => setDate(date)}
          />
        ) : null}
        <Col span={22}>{questionSelect}</Col>
     
        <Col span={2}>
          <Button
            size="large"
            style={{ borderRadius: "30px" }}
            onClick={resetQuestionAndAnswer}
            block
          >
            Reset
          </Button>
        </Col>
      </Row>

      {/* Render selected tags as clickable buttons */}
      <div style={{ marginTop: "10px" }}>
        {selectedTags.map((tag) => (
          <Tag
            key={tag.key}
            closable
            onClose={() => handleRemoveTag(tag)}
            onClick={() => handleTagClick(tag)} // Make tags clickable
            style={{ marginBottom: "8px", cursor: "pointer" }} // Add pointer cursor
          >
            {tag.label}
          </Tag>
        ))}
      </div>

      {formComponent ? (
        formComponent
      ) : (
        <div style={{ marginBottom: "180px" }} />
      )}
      {chart}
      <Collapse accordion style={{ marginTop: "20px" }}>
        <Panel header="History" key="1">
          {QAList ? (
            <AnswersPage
              selectedQuestionItem={selectedQuestionItem}
              setSelectedQuestionItem={setSelectedQuestionItem}
              questionGroupId={selectedQuestionGroupId}
              getAnswers={QAList}
              pleaseUpdate={setPleaseUpdate}
            />
          ) : null}
        </Panel>
      </Collapse>
    </div>
  );
};

export default MyHealthWellnessData;
