import { DownOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function MainMenu() {

    const history = useHistory();

    const handleResourcesClick = () => {
        // Navigate to the desired link
        history.push('/resources');
    };
    const handleAppsClick = () => {
        // Navigate to the desired link
        history.push('/resources/Apps');
    };
    const handleCalculatorsClick = () => {
        // Navigate to the desired link
        history.push('/resources/Calculators');
    };
    const handleDocumentsClick = () => {
        // Navigate to the desired link
        history.push('/resources/Documents');
    };
    const handleDocumentsMealPlansClick = () => history.push("/resources/documents/meal-plans")
    const handleOtherDocumentsClick = () => history.push("/resources/documents/others")
    const handleWorksheetsClick = () => {
        // Navigate to the desired link
        history.push('/resources/Worksheets');
    };



    return (
        <>
            <Menu
                theme="dark"
                mode="horizontal"
                style={{
                    backgroundColor: 'blue',
                    display: 'flex',
                    padding: '0 20px', // Add horizontal padding  
                    lineHeight: '64px', // Set line height for vertical centering  
                }}
            >
                <Menu.Item key="/products" icon={<ShoppingCartOutlined />}>
                    <Link to="/products">Products</Link>
                </Menu.Item>
                <Menu.SubMenu
                    title="Services"
                    icon={<DownOutlined />}
                >
                    <Menu.Item key="32">
                        <Link to="/services?category=32">Dietician / Nutritionist</Link>
                    </Menu.Item>
                    <Menu.Item key="31">
                        <Link to="/services?category=31">Skin care</Link>
                    </Menu.Item>
                    <Menu.Item key="28">
                        <Link to="/services?category=28">Weight Loss</Link>
                    </Menu.Item>
                </Menu.SubMenu>

                <Menu.SubMenu
                    title="Resources"
                    style={{ width: '11em' }}
                    icon={<DownOutlined />}
                    onTitleClick={handleResourcesClick}
                >
                    {/* Apps */}
                    <Menu.SubMenu title="Apps"
                        onTitleClick={handleAppsClick}

                    >
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://balanceapp.com/',
                                }}
                                target="_blank"
                            >
                                Balanceapp
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://www.calorieking.com/',
                                }}
                                target="_blank"
                            >
                                Calorieking
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://www.headspace.com/home?_stsgnoredir=1',
                                }}
                                target="_blank"
                            >
                                Headspace
                            </Link>
                        </Menu.Item>

                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'http://healthymindsapp.ca/',
                                }}
                                target="_blank"
                            >
                                Healthymindsapp
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://insighttimer.com/',
                                }}
                                target="_blank"
                            >
                                Insighttimer
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://loseit.com/',
                                }}
                                target="_blank"
                            >
                                Loseit
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://myfitnesspal.com/',
                                }}
                                target="_blank"
                            >
                                My fitness pal
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://noom.com/',
                                }}
                                target="_blank"
                            >
                                Noom
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                to={{
                                    pathname: 'https://www.smilingmind.com.au/',
                                }}
                                target="_blank"
                            >
                                Smilingmind
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    {/* Calculating */}
                    <Menu.SubMenu title="Calculators & Screening Tools"
                        onTitleClick={handleCalculatorsClick}
                    >
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/bac-calculator.html',
                                }}
                                target="_blank"
                            >
                                Blood Alcohol Concentration (BAC) Calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/bmr-calculator.html',
                                }}
                                target="_blank"
                            >
                                BMR Calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/bmi-calculator.html',
                                }}
                                target="_blank"
                            >
                                BMI Calculator
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/riskcalc/diabetic.html"}}target="_blank">CVD risk calculator (Framingham)</Link></Menu.Item> */}

                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/body-fat-calculator.html',
                                }}
                                target="_blank"
                            >
                                Body Fat Calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.calculator.net/body-type-calculator.html',
                                }}
                                target="_blank"
                            >
                                Body Type Calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://bcrisktool.cancer.gov/calculator.html',
                                }}
                                target="_blank"
                            >
                                Breast cancer risk assessment tool
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.mdcalc.com/calc/10580/isth-scc-bleeding-assessment-tool#why-use',
                                }}
                                target="_blank"
                            >
                                Bleeding Assessment Tool (ISTH)
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/calorie-calculator.html',
                                }}
                                target="_blank"
                            >
                                Calorie Calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.calculator.net/calories-burned-calculator.html',
                                }}
                                target="_blank"
                            >
                                Calories Burned Calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://clincalc.com/cardiology/stroke/chads.aspx',
                                }}
                                target="_blank"
                            >
                                CHADS2 Calculator for Guiding Antithrombotic Treatment in Atrial
                                Fibrillation{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.mycanceriq.ca/Cancers/Cervical',
                                }}
                                target="_blank"
                            >
                                Cervical cancer risk assessment tool{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{ pathname: 'https://ccrisktool.cancer.gov/calculator.html' }}
                                target="_blank"
                            >
                                Colorectal Cancer Risk Assessment Tool
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/due-date-calculator.html',
                                }}
                                target="_blank"
                            >
                                Due Date Calculator{' '}
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/riskcalc/index.html?sex=F&age=64"}}target="_blank">Framingham CVD calculator</Link></Menu.Item> */}
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://frax.shef.ac.uk/FRAX/tool.aspx?country=19',
                                }}
                                target="_blank"
                            >
                                FRAX, Osteoporotic fracture risk calculator
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/GeneralCalculators.jsp"}}target="_blank">General conversions</Link></Menu.Item> */}
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://clincalc.com/cardiology/anticoagulation/hasbled.aspx',
                                }}
                                target="_blank"
                            >
                                HAS-BLED Calculator for Assessing Bleeding Risk in Atrial
                                Fibrillation{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.mycanceriq.ca/Cancers/Kidney',
                                }}
                                target="_blank"
                            >
                                Kidney cancer risk assessment tool{' '}
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/kidneyFailureRiskCalculator/kidneyFailureRiskCalculator.jsp?sex=F&age=64&demographic_no=1327"}}target="_blank">Kidney failure risk calculator </Link></Menu.Item> */}
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.calculator.net/lean-body-mass-calculator.html',
                                }}
                                target="_blank"
                            >
                                Lean Body Mass Calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://analysistools.cancer.gov/lungCancerRiskAssessment/#/',
                                }}
                                target="_blank"
                            >
                                Lung Cancer Risk Assessment Tool
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/tdee-calculator.html',
                                }}
                                target="_blank"
                            >
                                Macronutrient Calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.mycanceriq.ca/Cancers/Melanoma',
                                }}
                                target="_blank"
                            >
                                Melanoma risk assessment tool
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.calculator.net/lean-body-mass-calculator.html',
                                }}
                                target="_blank"
                            >
                                Osteoporotic Fracture risk calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname:
                                        'https://www.calculator.net/ovulation-calculator.html',
                                }}
                                target="_blank"
                            >
                                Ovulation Calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/period-calculator.html',
                                }}
                                target="_blank"
                            >
                                Period Calculator{' '}
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.mcw.edu/calculators/pregnancy-date',
                                }}
                                target="_blank"
                            >
                                Pregnancy calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/macro-calculator.html',
                                }}
                                target="_blank"
                            >
                                Simple calculator
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            {' '}
                            <Link
                                to={{
                                    pathname: 'https://www.calculator.net/tdee-calculator.html',
                                }}
                                target="_blank"
                            >
                                Total Daily Energy Expenditure (TDEE) Calculator
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/riskcalc/index.html?sex=F&age=48"}}target="_blank">Framingham </Link></Menu.Item> */}
                        {/* <Menu.Item>  <Link to={{ pathname:"https://nsbariatric.kai-oscar.com/oscar/oscarEncounter/calculators/kidneyFailureRiskCalculator/kidneyFailureRiskCalculator.jsp?sex=F&age=48&demographic_no=6955" }}target="_blank">Kidney failure risk calculator</Link></Menu.Item> */}
                    </Menu.SubMenu>  
                            {/* Documents */}
                            <Menu.SubMenu title="Documents"
                                onTitleClick={handleDocumentsClick}
                            >
                                {/* Meal Plants */}
                        <Menu.SubMenu title="Meal Plans"
                            onTitleClick={handleDocumentsMealPlansClick}
                            >
                                <Menu.Item>
                                <a
                                    href="https://heatwellbeing.com/dl/meal-plans/900-omni.pdf" 
                                    download="900-omini.pdf" > 900 omini
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a
                                    href="https://heatwellbeing.com/dl/meal-plans/900-veg.pdf" 
                                    download="900-veg.pdf" > 900 veg
                                </a>
                            </Menu.Item>

                            <Menu.Item>
                                <a
                                    href="https://heatwellbeing.com/dl/meal-plans/1200-omni.pdf" 
                                    download="1200-omni.pdf" > 1200 omi
                                </a>
                            </Menu.Item>

                            <Menu.Item>
                                <a
                                    href="https://heatwellbeing.com/dl/meal-plans/1200-veg.pdf" 
                                    download="1200-veg.pdf" > 1200 veg
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a
                                    href="https://heatwellbeing.com/dl/meal-plans/1500-omni.pdf" 
                                    download="1500-omni.pdf" > 1500 omni
                            </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a
                                    href="https://heatwellbeing.com/dl/meal-plans/1500-veg.pdf" 
                                    download="1500-veg.pdf" > 1500 veg
                                </a>
                            </Menu.Item>
                                    
                            <Menu.Item>
                                <a
                                    href="https://heatwellbeing.com/dl/meal-plans/1800-omni.pdf" 
                                    download="1800-omni.pdf" > 1800 omni
                                </a>
                            </Menu.Item>

                            <Menu.Item>
                                <a
                                    href="https://heatwellbeing.com/dl/meal-plans/1800-veg.pdf" 
                                    download="1800-veg.pdf" > 1800 veg
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a
                                    href="https://heatwellbeing.com/dl/meal-plans/2100-omni.pdf" 
                                    download="2100-omni.pdf" > 2100 omni
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a
                                    href="https://heatwellbeing.com/dl/meal-plans/2100-veg.pdf" 
                                    download="2100-veg.pdf" > 2100 veg
                                </a>
                            </Menu.Item>
                            
                        </Menu.SubMenu>
                         {/* Others */}
                         <Menu.SubMenu title="Others"
                            onTitleClick={handleOtherDocumentsClick}
                        >

                                <Menu.Item>
                                    <a
                                        href="https://heatwellbeing.com/dl/docs/defense-mechanisms-of-the-ego.png" 
                                        download="Defense Mechanisms of the Ego.png" // Name of the file to be downloaded
                                    >
                                        Defense mechanisms of the EGO
                                    </a>
                                </Menu.Item>
                                <Menu.Item>
                                    <a
                                        href="https://heatwellbeing.com/dl/docs/TFA-Triangle.png" 
                                        download="TFA Triangle.png" // Name of the file to be downloaded
                                    >
                                        TFA Triangle
                                    </a>
                                </Menu.Item>
                                <Menu.Item>
                                    <a
                                        href="https://heatwellbeing.com/dl/docs/TFA-Reactions.png" 
                                        download="TFA Reactions.png" // Name of the file to be downloaded
                                    >
                                        TFA Reactions
                                    </a>
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu.SubMenu>
                    {/* worksheet */}
                    <Menu.SubMenu title="Worksheets"
                        onTitleClick={handleWorksheetsClick}
                    >
                        <Menu.Item>
                            <a
                                href="https://heatwellbeing.com/dl/worksheets/DrValas-death-bed-questions.docx" // Path to the image in the resources folder
                                download="DrValas-death-bed-questions.docx" // Name of the file to be downloaded
                            >
                                Dr. Vala’s death bed Q’S
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a
                                href="https://heatwellbeing.com/dl/worksheets/'S+R=O' worksheet.docx" // Path to the image in the resources folder
                                download="'S+R=O' worksheet.docx" // Name of the file to be downloaded
                            >
                               S+R=O worksheet
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a
                                href="https://heatwellbeing.com/dl/worksheets/short-term-productivity-planner.docx" // Path to the image in the resources folder
                                download="short-term-productivity-planner.docx" // Name of the file to be downloaded
                            >
                               Short Term Productivity Planner
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a
                                href="https://heatwellbeing.com/dl/worksheets/why-worksheet.docx" // Path to the image in the resources folder
                                download="why-worksheet.docx" // Name of the file to be downloaded
                            >
                               The WHY Worksheet
                            </a>
                        </Menu.Item>
                        
                        
                        
                    </Menu.SubMenu>
                </Menu.SubMenu>
            </Menu>
        </>
    )
}

export default MainMenu
