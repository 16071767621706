import { Modal, Form, Button, message, Row, Col } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import Question from '../../../../components/inputs/Question';
import UnitConverter from '../../../../components/inputs/UnitConverter';
import AnswerService from '../../../../services/api/AnswerService';
import { convertToSaveUnit } from '../../../../utils/units';

const EditAnswerModal = ({ visible, dismiss, answer, pleaseUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(undefined);
  const [form] = Form.useForm();

  const hasMeasure =
    answer &&
    answer.question &&
    answer.question.type === 'number' &&
    answer.question.measure;

  useEffect(() => {

    if (!answer) {
      return;
    }

    const { value } = JSON.parse(answer.answer);
    form.setFieldsValue({ [answer.question.id]: value });
  }, [ form,answer]);

  const onFinish = useCallback(
    async (values) => {
      setLoading(true);



      let data;
      console.log("MEASTURE ",hasMeasure);
      if (hasMeasure && selectedUnit) {
        data = {
          [answer.question.id]: convertToSaveUnit(
            values[answer.question.id],
            selectedUnit,
            answer.question.measure,
          ),
        };
      } else {
        data = { ...values };
      }

      try {
 
        await AnswerService.editAnswer(answer.id, data);
        pleaseUpdate();
        message.success('Your answer has been updated.');
        // await getAnswers();
      } catch (error) {
        message.error(error.message);
      }
      setLoading(false);
    },
    [answer, hasMeasure, selectedUnit],
  );

  return answer ? (
    <Modal
      title="َUpdate Answer"
      visible={visible}
      onOk={dismiss}
      onCancel={dismiss}
      footer={[
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={form.submit}
            loading={loading}
          >
            Submit
          </Button>
        </Form.Item>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={3}>
          <Col span={hasMeasure ? 20 : 24}>
            <Form.Item
              label={answer.question.title}
              name={answer.question.id}
              rules={[
                { required: true, message: 'Please answer this question!' },
              ]}
            >
              <Question
                {...answer.question}
                options={JSON.parse(answer.question.options)}
              />
            </Form.Item>
          </Col>

          {hasMeasure ? (
            <Col span={4}>
              <Form.Item
                label=" "
                shouldUpdate={(prevValues, curValues) =>
                  prevValues[answer.question.id] !==
                  curValues[answer.question.id]
                }
              >
                {({ getFieldValue }) => {
                  return (
                    <UnitConverter
                      value={getFieldValue(answer.question.id)}
                      measure={answer.question.measure}
                      onChange={(val, unit) => {
                        setSelectedUnit(unit);
                      }}
                      isFormComponent
                    />
                  );
                }}
              </Form.Item>
            </Col>
          ) : null}
        </Row>
      </Form>
    </Modal>
  ) : null;
};

export default EditAnswerModal;
